export default [
  {
    path: '/inicio',
    name: 'app-inicio',
    component: () => import('@/views/app/inicio/Index.vue'),
  },
  {
    path: '/empresas',
    name: 'app-empresa',
    component: () => import('@/views/app/empresas/Index.vue'),
  },
  {
    path: '/empresas/membros',
    name: 'app-membros',
    component: () => import('@/views/app/empresas/gerenciarmembros.vue'),
  },
  {
    path: '/empresas/novo',
    name: 'app-novoempresa',
    component: () => import('@/views/app/empresas/novoempresa.vue'),

  },
  {
    path: '/lead',
    name: 'app-lead',
    component: () => import('@/views/app/lead/Index.vue'),
  },
  {
    path: '/kanban',
    name: 'app-lead-kanban',
    component: () => import('@/views/app/lead/kanban.vue'),
  },
  {
    path: '/lead/novo',
    name: 'app-novolead',
    component: () => import('@/views/app/lead/novolead.vue'),

  },
  {
    path: '/leadnovo/dados/:id',
    name: 'app-leadnovo-dados',
    meta: { action: 'user' },
    component: () => import('@/views/app/lead/Leadnovo/components/dados.vue'),

  },
  {
    path: '/espera',
    name: 'app-espera',
    component: () => import('@/views/app/lead/Espera/Espera.vue'),

  },
  {
    path: '/espera/dados/:id',
    name: 'app-espera-dados',
    component: () => import('@/views/app/lead/Espera/Dados.vue'),

  },
]
