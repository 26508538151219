import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import VueCompositionAPI from '@vue/composition-api'
import Loading from 'vue-loading-overlay'
import DataTable from 'laravel-vue-datatable'
import VueTheMask from 'vue-the-mask'
import { VueMaskFilter } from 'v-mask'
import i18n from '@/libs/i18n'
import Vue2Filters from 'vue2-filters'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueApexCharts from 'vue-apexcharts'
import router from './router'
import store from './store'
import App from './App.vue'
import './global-components'
import 'vue-loading-overlay/dist/vue-loading.css'
import '@axios'
import '@/libs/acl'

import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/@fake-db/db'

Vue.use(Vue2Filters)
Vue.use(Loading)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('multiselect', Multiselect)
Vue.use(BootstrapVue)
Vue.filter('VMask', VueMaskFilter)
Vue.use(require('vue-moment'))

Vue.use(VueTheMask)
Vue.use(DataTable)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCompositionAPI)
// Vue.use(VueGoogleCharts)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('vue-multiselect/dist/vue-multiselect.min.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
