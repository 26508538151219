export default [
  {
    path: '/leadinicio',
    name: 'app-inicio-user',
    meta: { action: 'user' },
    component: () => import('@/views/app/inicio/Index.vue'),
  },
  {
    path: '/leaduser',
    name: 'app-lead-user',
    meta: { action: 'user' },
    component: () => import('@/views/app/lead/Index.vue'),
  },
  {
    path: '/leadnovo',
    name: 'app-leadnovo',
    meta: { action: 'user' },
    component: () => import('@/views/app/lead/Leadnovo/leadnovo.vue'),

  },
  {
    path: '/espera',
    name: 'app-espera-user',
    meta: { action: 'user' },
    component: () => import('@/views/app/lead/Espera/Espera.vue'),

  },
]
